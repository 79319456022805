import React from "react"
import ContactSection from "../../components/common/ContactSection"
import { contactSection } from "../../content/Common"
import Layout from "../../components/layout"
import Seo from "../../components/seo"


const SecurityAndFraud = () => (
  <Layout>
    <Seo title="SecurityAndFraud" />

    {/* <InnerArticlePage post={post}  /> */}

    <ContactSection data={contactSection} sectionTitleAlignment={"center"} />
  </Layout>
)

export default SecurityAndFraud
